import React, { useEffect } from 'react';
import './projects.css';
import './projectsMobile.css';

import {TopSpotsLogo, DissLogo, AlbumdleLogo, Arrow, QALogo, MosaicTapLogo} from "../../img";

import {CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {ProjectSlide, resetLogo} from "../../components";
import anime from "animejs";
// import {useLocation} from "react-router-dom";

const Projects = (props) => {
    // let location = useLocation();

    function detectMob() {
        return window.innerWidth <= 640;
    }

    useEffect(() => {
        document.title = `Oliver Fox - Projects`;
        resetLogo();

        let basicTimeline = anime.timeline({
            easing: 'easeInOutQuad'
        });

        basicTimeline
            .add({
                targets: '.projectSlide',
                translateX: [-10, 0],
                opacity: ['0%', '100%'],
                duration: 400,
            })
    }, [])

    return (
        <div className='projectContainer noSelect'>
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={1000}
                totalSlides={5}
            >
                <Slider>
                    <Slide index={0}>
                        <div className='projectSlide slide1'>
                            <ProjectSlide
                                title={'Mosaic Tap'}
                                titleSize={'4vw'}
                                desc={["An interactive website built for the pub ", <b>Mosaic Tap</b>, " in Newcastle upon Tyne."]}
                                img={MosaicTapLogo}
                                alt={'Mosaic Tap Logo'}
                                nav={'mosaic-tap'}/>
                        </div>
                    </Slide>
                    <Slide index={1}>
                        <div className='projectSlide slide2'>
                            <ProjectSlide
                                title={'Limitations of Graph Query Languages'}
                                titleSize={'4vw'}
                                desc={["First research project for my PhD, investigating the limitations of Graph " +
                                "Query Languages (GQL)."]}
                                img={QALogo}
                                alt={'Graph Query Languages Logo'}
                                nav={'gql-limitations'}/>
                        </div>
                    </Slide>
                    <Slide index={2}>
                        <div className='projectSlide slide3'>
                            <ProjectSlide
                                title={'Albumdle'}
                                desc={["A web application that can be played daily, with the goal to try and " +
                                "guess an album cover, as it's revealed one tile at a time."]}
                                img={AlbumdleLogo}
                                alt={'Albumdle logo'}
                                nav={'albumdle'}/>
                        </div>
                    </Slide>
                    <Slide index={3}>
                        <div className='projectSlide slide4'>
                            <ProjectSlide
                                title={'Top Spots'}
                                desc={['An application I created in lockdown for people to ' +
                                'discover and add new ', <i>spots</i>, ' near them.']}
                                img={TopSpotsLogo}
                                alt={'Top Spots logo'}
                                nav={'top-spots'}/>
                        </div>
                    </Slide>
                    <Slide index={4}>
                        <div className='projectSlide slide5'>
                            <ProjectSlide
                                title={['Third Year Dissertation']}
                                desc={['A dissertation project involving using reinforcement ' +
                                'learning to train a car to navigate through a city, with obstacles.']}
                                img={DissLogo}
                                alt={'Third Year Dissertation logo'}
                                nav={'dissertation'}/>
                        </div>
                    </Slide>
                </Slider>
                <div className='dotContainer'>
                    <Dot className='noSelect' slide={0}/>
                    <Dot className='noSelect' slide={1}/>
                    <Dot className='noSelect' slide={2}/>
                    <Dot className='noSelect' slide={3}/>
                    <Dot className='noSelect' slide={4}/>
                </div>
                <div>
                    {detectMob() ?
                        <div></div>
                        :
                        <ButtonBack>
                            <img className='arrow arrowBack' src={Arrow} alt={'Back arrow'} />
                        </ButtonBack>
                    }
                    {detectMob() ?
                        <div></div>
                        :
                        <ButtonNext>
                            <img className='arrow arrowNext' src={Arrow} alt={'Next arrow'} />
                        </ButtonNext>
                    }
                </div>
            </CarouselProvider>
        </div>
    )
}

export default Projects