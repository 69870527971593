import React from 'react';
import './projectSlide.css';
import './projectSlideMobile.css';

import { useNavigate } from "react-router-dom";
import anime from "animejs";

const ProjectSlide = (props) => {
    const navigate = useNavigate();

    let title = props.title;
    let titleSize = props.titleSize;
    let desc = props.desc;
    let img = props.img;
    let alt = props.alt;
    let nav = props.nav;

    return (
        <div className='projectContent noSelect' onClick={() => {
            anime({
                targets: '.projectContent',
                scale: [1.05, 1.1],
                opacity: ['100%', '0%'],
                easing: 'easeInOutQuad',
                duration: 200,
                complete: function(anim) {
                    if (anim.completed) {
                        setTimeout(function() {
                            navigate(`/projects/${nav}`)
                        }, 500)
                    }
                }
            });
        }}>
            <img className='projectImg' src={ img } alt={alt} />
            <div className='projectText'>
                <div className='projectTitle' style={{fontSize: titleSize}}>{title}</div>
                <div className='projectDesc'>{desc}</div>
            </div>
        </div>
    )
}

export default ProjectSlide