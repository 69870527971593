import React, {useEffect} from 'react';
import './about.css';
import './aboutMobile.css';
import {AboutProfile, Location, Parchment, ThatsMe, ThatsMeUp} from "../../img";
import anime from "animejs";
import {resetLogo, SpinLogo, Timeline} from "../../components";
import {useNavigate} from "react-router-dom";

const About = (props) => {
    const navigate = useNavigate();
    // const location = useLocation();

    let setOpen = props.setOpen;
    let setDialogContent = props.setDialogContent;
    let setStyle = props.setDialogStyle;

    function detectMob() {
        return window.innerWidth <= 640;
    }

    useEffect(() => {
        document.title = `Oliver Fox - About`;
        resetLogo();

        let basicTimeline = anime.timeline({
            easing: 'easeInOutQuad'
        });

        basicTimeline
            .add({
                targets: '.aboutPhotoContainer',
                translateY: [10, 0],
                opacity: ['0%', '100%'],
                duration: 400,
            })
            .add({
                targets: ".thatsMe",
                translateX: [-10, 0],
                opacity: ['0%', '100%'],
                duration: 400,
            }, '-=200')
            .add({
                targets: ".aboutDesc",
                translateY: [-10, 0],
                opacity: ['0%', '100%'],
                duration: 200,
            }, '-=250')
            .add({
                targets: ".timelineContainer",
                translateY: [-10, 0],
                opacity: ['0%', '100%'],
                duration: 200,
            }, '-=150');

    }, [])

    return (
        <div onScroll={SpinLogo} className='aboutContainer contentPageContainer noSelect'>
            <div className='meContainer'>
                <img className='thatsMe' src={detectMob() ? ThatsMeUp : ThatsMe} alt={"Handwritten text saying that's me"} />
                <div className='aboutPhotoContainer'>
                    <img onMouseEnter={() => {
                        if (!detectMob()) {
                            anime({
                                targets: '.locationDiv',
                                marginTop: [-75, 20],
                                opacity: ['0%', '100%'],
                                easing: 'spring',
                                duration: 200
                            });
                        }

                    }} onMouseLeave={() => {
                        if (!detectMob()) {
                            anime({
                                targets: '.locationDiv',
                                marginTop: [20, -75],
                                opacity: ['100%', '0%'],
                                easing: 'spring',
                                duration: 200
                            });
                        }
                    }} alt='Oliver Fox next to the Angel of the North' className='aboutProfile noSelect' src={AboutProfile} />
                    <div className='locationDiv'>
                        <img className='locationPin' src={Location} alt={'Location pin'} />
                        <div className='locationName'>Angel of the North</div>
                    </div>
                </div>
            </div>

            <div className='aboutDesc'>
                <h2>About Me</h2>
                Computing has always been an interest of mine from an early age, I've now taken that interest
                and tried to apply it in every way I can, keeping a dedication behind every project or work I undertake.
                Challenges that take all the steps to expand my knowledge are often favoured.
                <br/><br/>
                Dedicated with highly praised interpersonal skills and the ability to adapt to challenges, thus making
                a strong asset in a working environment. A highly adaptable individual with extensive industry experience,
                having taught coding for numerous years, created code for a large organisation and greatly
                enhanced my industry skills as an IBM Extreme Blue intern, learning about working with clients,
                collaboratively working with other young professionals and more.
                <br/><br/>
                I also have a love for music; listening, exploring, following, and managing bands. As well as creating my
                own with a partner, under the alias <a  href={"https://bloatware.co.uk"}>Bloatware</a>.

                <br/><br/>
                <h2>Education</h2>
                Graduated with a <b className={"aboutProjectLink"} onClick={() => {
                    setOpen(true)
                    setDialogContent([<img className={'parchment'} src={Parchment} alt={'Parchment showing Oliver graduating with a first-class honours in Computer Science from Newcastle University.'}/>])
                    if (detectMob()) {
                        setStyle({width: "85%", padding: "0"});
                    } else {
                        setStyle({height: "calc(100vh - 150px)", width: "auto"});
                    }
                }}><i>First Class Honours</i> in <i>Master of Computer Science</i> from <i>Newcastle University</i></b> in
                2023 and continuing my academic journey by pursing a PhD in <i>Uncertainty Reasoning with Logical Guarantees</i>.

                <br/><br/>
                <h2>Work Experience</h2>
                Below is a timeline of my most notable jobs that I've worked from 2014 until the present, detailing my
                progression in developing skills and experience.
            </div>

            <Timeline />

            <div className='aboutDesc skillBoxContainer'>
                <h2 className={'skillsTitle'}>Extracurricular / Skills</h2>
                <div className={'skillBoxContainer'}>
                    <div className={'skillBox noSelect'}>Java</div>
                    <div className={'skillBox noSelect'}>Python</div>
                    <div className={'skillBox noSelect'}>C#</div>
                    <div className={'skillBox noSelect'}>React</div>
                    <div className={'skillBox noSelect'}>Photoshop</div>
                    <div className={'skillBox noSelect'}>Illustrator</div>
                    <div className={'skillBox noSelect'}>Vegas Pro</div>
                    <div className={'skillBox noSelect'}>Personal Projects</div>
                    <div className={'skillBox noSelect'}>Music</div>
                    <div className={'skillBox noSelect'}>Cycling</div>
                </div>
            </div>

            <div className='aboutDesc checkOut'>
                Why not check out my <i onClick={() => {navigate('/projects')}} className='aboutProjectLink'>projects</i> if you haven't already!
            </div>
        </div>
    )
}

export default About