import React from 'react';
import './navbar.css';
import './navbarMobile.css';

import { useNavigate } from "react-router-dom";
import { Burger, LinkedIn, Logo, Mail } from "../../img";

const Navbar = (props) => {
    const navigate = useNavigate();

    let setOpen = props.setOpen;
    let setDialogContent = props.setDialogContent;

    function detectMob() {
        return window.innerWidth <= 1024;
    }

    function detectHeader() {
        return window.innerWidth <= 1400;
    }

    function openNav() {
        document.getElementsByClassName("sidenav")[0].style.width = "200px";
    }

    return (
        <div className='navbar noSelect'>
            <div className='navbarContent'>
                <div className='navbarContainer'>
                    {detectHeader() ?
                        <div className='navbarLinks'>
                            <img alt="Side navigation bar" onClick={() => {openNav()}} className='burger link noSelect' src={ Burger }/>
                        </div>
                    :
                        <div className='navbarLinks'>
                            <div className='link noSelect' onClick={() => {navigate('/')}}>Home</div>
                            <div className='link noSelect' onClick={() => {navigate('/projects')}}>Projects</div>
                            {/*<div className='link noSelect' onClick={() => {navigate('/work/development')}}>Development</div>*/}
                            <div className='link noSelect' onClick={() => {navigate('/work/design')}}>Design</div>
                            <div className='link noSelect' onClick={() => {navigate('/about')}}>About</div>
                        </div>
                    }

                    <div className='logoDiv'>
                        <img onClick={() => {navigate('/')}} alt='Logo for Oliver Fox' className='logo noSelect' src={Logo} />
                    </div>

                    {detectMob() ?
                      <div className='socialLinks'>
                          <img onClick={() => {
                              setOpen(true)
                              setDialogContent([<div>I am available for commission, if you would like to contact me to
                                  discuss collaboration or further detail of my previous work, please contact: <a
                                    style={{pointerEvents: 'auto'}} href={'mailto:hello@ofox.co.uk'} rel={"noreferrer"}
                                    target={'_blank'}>hello@ofox.co.uk</a></div>])
                          }} alt='Email' className='socialLink mail' src={Mail}/>
                      </div>
                      :
                      <div className='socialLinks'>
                          <img onClick={() => window.open('https://www.linkedin.com/in/oliver-fox/')} alt='LinkedIn'
                               className='socialLink linkedIn' src={LinkedIn}/>
                          <img onClick={() => {
                              setOpen(true)
                              setDialogContent([<div>I am available for commission, if you would like to contact me and
                                  discuss collaboration or further detail of my previous work, please contact: <a style={{pointerEvents: 'auto'}} href={'mailto:hello@ofox.co.uk'} rel={"noreferrer"} target={'_blank'}>hello@ofox.co.uk</a></div>])
                            }} alt='Email' className='socialLink mail' src={Mail} />
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default Navbar