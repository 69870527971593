import React, { useEffect } from 'react';
import './home.css';
import './homeMobile.css';

import {Profile} from "../../img";

import {useNavigate} from "react-router-dom";

import anime from "animejs";
import { ImageWithLocation } from "../../components";

import {resetLogo} from "../../components";

const Home = (props) => {
    const navigate = useNavigate();
    // const location = useLocation();

    function detectMob() {
        return window.innerWidth <= 640;
    }

    useEffect(() => {
        document.title = `Oliver Fox - Portfolio Website`;
        resetLogo();

        let basicTimeline = anime.timeline({
            easing: 'easeInOutQuad'
        });

        basicTimeline
            .add({
                targets: '.infoText1',
                translateY: [10, 0],
                opacity: ['0%', '100%'],
                duration: 400,
            })
            .add({
                targets: ".infoContainer",
                translateY: [10, 0],
                opacity: ['0%', '100%'],
                duration: 400,
            }, '-=200')
            .add({
                targets: ".imgLocContainer",
                translateX: [-10, 0],
                opacity: ['0%', '100%'],
                duration: 400,
            }, '-=250');
    }, [])

    return (
        <div className='homeInfoContainer'>
            <div>
                <div className='infoText1 noSelect'>I'm Oliver Fox.</div>
                <div className='infoContainer'>
                    <div className='infoText2'>
                        <div onClick={() => {
                            navigate("/work/development")
                        }} className='developerText noSelect'>Developer
                        </div>
                        <div className='infoText3 noSelect'>,&nbsp;</div>
                        <div onClick={() => {
                            navigate("/work/media")
                        }} className='artistText noSelect'>Designer
                        </div>
                        <div className='infoText3 noSelect'>,</div>
                    </div>

                    <div className='infoText2'>
                        <div onClick={() => {
                            navigate("/projects")
                        }} className='amongText noSelect'>Doer
                        </div>
                        <div className='infoText3 noSelect'>.</div>
                    </div>
                </div>
            </div>

            <ImageWithLocation img={Profile} location={'Newcastle University'}
                               alt={"Oliver Fox graduating at Newcastle University."}
                               marginTopBefore={detectMob() ? null : '-45px'}
                               marginTopAfter={detectMob() ? null : '20px'}/>
        </div>
    )
}

export default Home