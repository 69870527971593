import React from 'react';
import './timeline.css';
import './timelineMobile.css';
import {Circle} from "../index";

const Timeline = (props) => {
    return (
        <div className='timelineContainer'>
            <Circle title={'Web Developer'}
                company={'Mosaic Tap'}
                desc={'Created a website from the ground up for a pub, Mosaic Tap, assisting their outreach to new customers and rebranding to their unique aesthetic.'}
                side={'right'}
                start={"May 24'"}
                end={"June 24'"}/>
            <div className='gapLine'></div>

            <Circle title={'Lab Support Assistant'}
                    company={'Newcastle University'}
                    desc={'Assisting stage 1, 2 and 3 computing students at Newcastle University, as well as helping with the hiring process of new lecturers for the university.'}
                    side={'left'}
                    start={"Sep 22'"}
                    end={"Present"}/>
            <div className='gapLine'></div>

            <Circle title={'Freelance Software Developer'}
                    company={'Business Smart Solutions'}
                    desc={'Developing a timesheet management app for Android, iOS, and Web, to enable better management of timesheets and payments within the company.'}
                    side={'right'}
                    start={"Apr 21'"}
                    end={"Present"}/>
            <div className='gapLine'></div>

            <Circle title={'Extreme Blue Intern'}
                    company={'IBM'}
                    desc={'Worked as an Extreme Blue intern alongside young professionals with one of IBM’s top clienteles, one of the largest department stores in the UK.'}
                    side={'left'}
                    start={"Jun 22'"}
                    end={"Sep 22'"}/>
            <div className='gapLine'></div>

            <Circle title={'Virtual Tutor'}
                    company={'FunTech'}
                    desc={'Providing young students with a deeper understanding of Computer Science, catering to their academic development.'}
                    side={'right'}
                    start={"Jul 20'"}
                    end={"Dec 21'"}/>
            <div className='gapLine'></div>

            <Circle title={'Revenues Assistant'}
                    company={'Surrey Health Borough Council'}
                    desc={'Created a program to help businesses in the local area and distribute business grants to maximise efficiency during the COVID-19 pandemic.'}
                    side={'left'}
                    start={"Mar 20'"}
                    end={"May 20'"}/>
            <div className='gapLine'></div>

            <Circle title={'Teacher'}
                    company={'Code Camp'}
                    desc={'Taught to over a hundred students aged 7-13 in JavaScript and Code Camp’s own programming environment.'}
                    side={'right'}
                    start={"Jul 19'"}
                    end={"Jul 20'"}/>
            <div className='gapLine'></div>

            <Circle title={'Teaching Assistant'}
                    company={'Kumon'}
                    desc={'Made sure the department ran smoothly and the students were confident with the work they were completing.'}
                    side={'left'}
                    start={"May 14'"}
                    end={"Sep 19'"}
                    last={true}/>
        </div>
    )
}

export default Timeline