import React, { useEffect } from 'react';
import './projectPage.css';
import './projectPageMobile.css';
import anime from "animejs";
import {useLocation, useNavigate} from "react-router-dom";
import {Arrow, Parchment} from "../../img";

const ProjectPage = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    let img = props.img;
    let title = props.title;
    let intro = props.intro;
    let desc = props.desc;
    let screenshots = props.screenshots;
    let skills = props.skills;
    let background = props.background;
    let prev = props.prev;
    let next = props.next;

    let imgSize = props.imgSize
    let setOpen = props.setOpen;
    let setDialogContent = props.setDialogContent;
    let setStyle = props.setDialogStyle;

    function detectMob() {
        return window.innerWidth <= 640;
    }

    useEffect(() => {
        document.title = `Oliver Fox - ${title}`

        let basicTimeline = anime.timeline({
            easing: 'easeInOutQuad'
        });

        basicTimeline
            .add({
                targets: '.projectPageImg',
                translateY: [-10, 0],
                opacity: ['0%', '100%'],
                duration: 400,
            })
            .add({
                targets: ".projectPageTitleContainer",
                translateY: [-10, 0],
                opacity: ['0%', '100%'],
                duration: 400,
            }, '-=150')
            .add({
                targets: ".projectPageDesc",
                translateY: [-10, 0],
                opacity: ['0%', '100%'],
                duration: 200,
            }, '-=175')
            .add({
                targets: ".screenshots",
                translateY: [-10, 0],
                opacity: ['0%', '100%'],
                duration: 200,
            }, '-=100');
    }, [location])

    return (

        <div className='projectPageContainer noSelect'>
            <div className='projectSwitcher'>
                <div onClick={() => {navigate(prev)}} className='projectSwitcherInner'>
                    {detectMob() ? '': 'Previous'}
                    <div className='projectButton'>
                        <img className='arrow arrowBack' src={Arrow} alt={'Back arrow'} />
                    </div>
                </div>
                <div>
                    <div className='switcherTitle'>{title}</div>
                </div>
                <div onClick={() => {
                    navigate(next)
                }} className='projectSwitcherInner'>
                    <div className='projectButton'>
                        <img className='arrow arrowNext' src={Arrow} alt={'Next arrow'} />
                    </div>
                    {detectMob() ? '': 'Next'}
                </div>
            </div>

            <div className='projectPageDesc'>
                <div className='projectPageLogoContainer'>
                    <img className='projectPageImg' src={img} alt={`${title} logo`}/>
                    {/*<h1 className='projectPageTitle'>{title}</h1>*/}
                    <div className='projectPageIntro'>{intro}</div>
                </div>
                <h2 className='projectPageTitle'>Information</h2>
                {desc}
            </div>
            <div className='screenshots'>
                {screenshots.map(screenshot => <img onClick={() => {
                    setOpen(true)
                    setDialogContent([<img className={'openScreenshot'} src={screenshot[0]} alt={screenshot[1]}/>])
                    if (detectMob()) {
                        setStyle({width: "85%", padding: "0"});
                    } else {
                        setStyle({height: "calc(80vh - 150px)", width: "auto"});
                    }
                }} className='screenshot' style={{width: !detectMob() ? imgSize : ''}} src={screenshot[0]}  alt={screenshot[1]}/>)}
            </div>
            <div className='projectPageDesc'>
                <h2 className='projectPageTitle'>Skills / Technologies</h2>
                <div>Below are some of the skills and technologies developed in the process of this project:</div>
                {skills}
            </div>


        </div>
    )
}

export default ProjectPage