import React, { useEffect } from 'react';
import './demo.css';
import './demoMobile.css';

import { Unity, useUnityContext } from "react-unity-webgl";

const Demo = (props) => {
    const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
        loaderUrl: "/Build/WebGLReact.loader.js",
        dataUrl: "/Build/WebGLReact.data.unityweb",
        frameworkUrl: "/Build/WebGLReact.framework.js.unityweb",
        codeUrl: "/Build/WebGLReact.wasm.unityweb",
    });

    const loadingPercentage = Math.round(loadingProgression * 100);

    useEffect(() => {
    })

    return (
        <div className="demoContainer">
            {isLoaded === false && (
                // We'll conditionally render the loading overlay if the Unity
                // Application is not loaded.
                <div className="loadingOverlay">
                    <p>Loading... ({loadingPercentage}%)</p>
                </div>
            )}
            <Unity
                className="unity"
                unityProvider={unityProvider}
                devicePixelRatio={1}
            />
        </div>
    )
}

export default Demo