import anime from "animejs";

const SpinLogo = event => {
    let logo = document.querySelector('.logo');
    let percentage = (event.currentTarget.scrollTop / (event.currentTarget.scrollHeight - event.currentTarget.clientHeight))
    if (logo) {
        logo.style.transform = `rotate(${percentage * 360}deg)`;
    }
};

// Reset logo to 0 degrees if rotated
export function resetLogo() {
    anime({
        targets: '.logo',
        rotate: [anime.get(document.querySelector('.logo'), 'rotate', 'deg'), 0],
        duration: 400,
        easing: 'easeInOutQuad'
    });
}

export default SpinLogo;