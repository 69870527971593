import React, { useEffect } from 'react';
import './development.css';
import './developmentMobile.css';
import anime from "animejs";

import {CodingImg, Squad, Badge, MicrophonePhoto, GroupPhoto, BSS1, BSS2, BSSDesktop} from "../../../img/work/dev";

import {ImageWithLocation, resetLogo} from "../../../components";
import {useLocation} from "react-router-dom";

const Development = (props) => {
    const location = useLocation();

    function detectMob() {
        return window.innerWidth <= 640;
    }

    useEffect(() => {
        document.title = `Oliver Fox - Development`;
        resetLogo();

        let basicTimeline = anime.timeline({
            easing: 'easeInOutQuad'
        });

        basicTimeline
            .add({
                targets: '.devContainer',
                translateY: [-10, 0],
                opacity: ['0%', '100%'],
                duration: 600,
            })
    }, [location])

    return (
        <div className='devContainer'>
            <ImageWithLocation img={CodingImg} location={'Berlin'}
                               alt={"Oliver Fox in the DDR Museum in Berlin using a typewriter"} width={detectMob() ? "85%" : "max(30%, 500px)"}
                               direction={'column-reverse'} marginTopBefore={0} marginTopAfter={0}
                               marginBottomBefore={-75} marginBottomAfter={20}
                               class={'devImg'}/>
            <div className='devDesc'>
                <h2>Introduction</h2>
                Being able to take a hobby I'm passionate about, and use it for work is something I do not take for granted.
                I get stuck into the work I'm set out to do, and create a product of top quality standard.
                <br/><br/>
                Previously employed by Surrey Heath Borough Council as a key-worker during the COVID-19 pandemic,
                creating software to help automate the response to giving out business retail grants for companies in
                the local area.
                <br/><br/>
                Now, I have a long history of programming experience including proficiency in: Java, JavaScript, Python, C#.
                Furthermore, knowledge of Android, iOS and Web app development through personal app creations, as well as work for <i>Business Smart Solutions</i>.
            </div>

            <div className='devDesc'>
                <h2>IBM</h2>
                Over the Summer of 2022, I worked at IBM as an Extreme Blue intern, where I worked with a small team of
                young professionals to develop a product for one of the largest retail departments in the UK. This brought my
                agile, networking and career skills to a new level, gaining quality experience in the process.
                <br/><br/>
                <div className='screenshots'>
                    {[[Squad, 'The Extreme Blue team Oliver worked with.'], [Badge, 'IBM card with three pins attached to the lanyard.']]
                        .map(screenshot => <img className='screenshot' src={screenshot[0]}  alt={screenshot[1]}/>)}
                </div>
                <br/>
                In the team of five I was assigned to, we developed an example of Digital Product Passports, to help
                redesign how we think about sustainability and circularity in the modern age. An idea that will be implemented
                in EU standards by 2024, and something we wanted to explore to help extend a products life.
                <br/><br/>
                <div className='screenshots'>
                    {[[MicrophonePhoto, "Oliver presenting for the final demonstration of the project."], [GroupPhoto, 'The entire Extreme Blue team stood in front of Hursley house.']]
                        .map(screenshot => <img className='screenshot' src={screenshot[0]} alt={screenshot[1]}/>)}
                </div>
            </div>

            <div className='devDesc'>
                <h2>BSS Hub</h2>
                My work for <i>Business Smart Solutions</i> has been to create a timesheet management app, on Web, Android and iOS natively.
                This was undertaken on my own, and has helped grow my skills in all areas of development, especially with
                working on multiple operating platforms.
                <br/><br/>
                <div className='screenshots'>
                    {[[BSSDesktop, 'Screenshot of BSS Hub on desktop.']]
                        .map(screenshot => <img className='screenshot' src={screenshot[0]} alt={screenshot[1]}/>)}
                </div>
                <br/>
                The Web app was developed with React <i className={'however'}>(Much like this website you're on right now)</i>,
                which gave me the chance to use skills incorporated with this website on a delivered product.
                <br/><br/>
                For the Android version of the app, it was developed in Java, and in Swift for iOS.
                <br/><br/>
                <div className='screenshots'>
                    {[[BSS1, 'Home page for Android BSS Hub.'], [BSS2, 'Adding hours for Android BSS Hub']]
                        .map(screenshot => <img className='screenshot' src={screenshot[0]} alt={screenshot[1]}/>)}
                </div>
            </div>
        </div>
    )
}

export default Development