import React, { useEffect } from 'react';
import './work.css';
import './workMobile.css';
import { DeveloperBG, MediaBG } from "../../img/work";
import anime from "animejs";
import {useNavigate} from "react-router-dom";
import {resetLogo} from "../../components";

const Work = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `Oliver Fox - Work`;
        resetLogo();

        let basicTimeline = anime.timeline({
            easing: 'easeInOutQuad'
        });

        basicTimeline
            .add({
                targets: '.developer',
                translateX: [-20, 0],
                opacity: ['0%', '100%'],
                duration: 200,
            })
            .add({
                targets: '.media',
                translateX: [20, 0],
                opacity: ['0%', '100%'],
                duration: 200,
            }, '-=200')
            .add({
                targets: '.workTextContainer',
                opacity: ['0%', '100%'],
                duration: 400,
            }, '-=150')
    }, [])

    return (
        <div className='workContainer'>
            <div className='workTextContainer'>
                <div className='workDeveloperText'>Development</div>
                <div className='workMediaText'>Media</div>
            </div>

            <div className='workImageContainer noSelect'>
                <div className='developer'
                     style={{ backgroundImage: `url(${DeveloperBG})`}}
                     onClick={() => {
                         anime({
                             targets: '.workTextContainer',
                             opacity: ['100%', '0%'],
                             easing: 'easeInOutQuad',
                             duration: 100
                         });
                         anime({
                             targets: '.workImageContainer',
                             scale: [1, 1.05],
                             opacity: ['100%', '0%'],
                             easing: 'easeInOutQuad',
                             duration: 200,
                             complete: function(anim) {
                                 if (anim.completed) {
                                     setTimeout(function() {
                                         navigate(`/work/development`)
                                     }, 500)
                                 }
                             }
                         });
                     }}>
                </div>
                <div className='media'
                     style={{ backgroundImage: `url(${MediaBG})`}}
                     onClick={() => {
                         anime({
                             targets: '.workTextContainer',
                             opacity: ['100%', '0%'],
                             easing: 'easeInOutQuad',
                             duration: 100
                         });
                         anime({
                             targets: '.workImageContainer',
                             scale: [1, 1.05],
                             opacity: ['100%', '0%'],
                             easing: 'easeInOutQuad',
                             duration: 200,
                             complete: function(anim) {
                                 if (anim.completed) {
                                     setTimeout(function() {
                                         navigate(`/work/media`)
                                     }, 500)
                                 }
                             }
                         });
                     }}>
                </div>
            </div>
        </div>
    )
}

export default Work