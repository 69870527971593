import React from 'react';
import './sidenav.css';

import {LinkedIn, Mail} from "../../img";
import { useNavigate } from "react-router-dom";

const Sidenav = (props) => {
    const navigate = useNavigate();

    let setOpen = props.setOpen;
    let setDialogContent = props.setDialogContent;

    function navigatePage(page) {
        navigate(page);
        closeNav();
    }

    function closeNav() {
        document.getElementsByClassName("sidenav")[0].style.width = "0";
    }

    return (
        <div className="sidenav">
            <a className="closebtn" onClick={() => {closeNav()}}>&times;</a>
            <a onClick={() => {navigatePage('/')}}>Home</a>
            <a onClick={() => {navigatePage('/projects')}}>Projects</a>
            {/*<a onClick={() => {navigatePage('/work/development')}}>Development</a>*/}
            <a onClick={() => {navigatePage('/work/design')}}>Design</a>
            <a onClick={() => {navigatePage('/about')}}>About</a>

            <div className={'socialSidenav'}>
                <img onClick={() => window.open('https://www.linkedin.com/in/oliver-fox/')} className={'socialSidenavIcon'} src={LinkedIn} alt={'Link to LinkedIn'} />
                <img onClick={() => {
                    closeNav();
                    setOpen(true)
                  setDialogContent([<div>I am available for commission, if you would like to contact me and discuss collaboration or further detail of my previous work, please contact: <a style={{pointerEvents: 'auto'}} href={'mailto:hello@ofox.co.uk'} rel={"noreferrer"} target={'_blank'}>hello@ofox.co.uk</a></div>])
                }} className={'socialSidenavIcon sidenavMail'} src={Mail} alt={'Link to email'} />
            </div>
        </div>
    )
}

export default Sidenav