import './App.css';
import './AppMobile.css';
import './fonts/index.css'

import React, {useState} from "react";
import { Home, Projects, Work, About, Sidenav, Navbar, Media, RLDemo, ContactDialog, Development } from "./containers";
import {ProjectPage, SpinLogo} from "./components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Images
import {AlbumdleLogo, TopSpotsLogo, DissLogo, QALogo, MosaicTapLogo} from "./img";
import {HowToAlbum, DemoGif, ShareSS, ResultsSS} from "./img/projects/albumdle";
import { LargeCityCloseUp, LargeCityTraining, AppliedNN } from "./img/projects/thirdYearDiss";
import { TS1, TS2, TS3, TS4 } from "./img/projects/topSpots";
import GQLLimitations from "./containers/gqlLimitations/GQLLimitations";
import {
    MosaicCraftBeer, MosaicEvents,
    MosaicGallery,
    MosaicLandingPage, MosaicTapWallGIF,
    MosaicVisitUs,
    MosaicWallMobile
} from "./img/projects/mosaicTap";

function App() {
    let [open, setOpen] = useState(false);
    let [dialogContent, setDialogContent] = useState("")
    let [dialogStyle, setDialogStyle] = useState({})

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    return (
      <div className="App">
        <Router>
            <div className='pageContainer'>
                <Sidenav setOpen={setOpen} setDialogContent={setDialogContent} />
                <Navbar setOpen={setOpen} setDialogContent={setDialogContent} />
                <ContactDialog open={open} setOpen={setOpen} content={dialogContent} style={dialogStyle} setStyle={setDialogStyle}/>
                <div onScroll={SpinLogo} className='innerPageContainer'>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/projects" element={<Projects />} />
                        <Route exact path="/projects/mosaic-tap" element=
                            {<ProjectPage
                                img={MosaicTapLogo}
                                title={'Mosaic Tap'}
                                intro={["An interactive website built for the pub ", <b>Mosaic Tap</b>, " in Newcastle upon Tyne."]}
                                desc={["Working with the owner of the Mosaic Tap, I created an interactive website that accurately " +
                                "portrays the unique aesthetic of this craft beer house, with a skeuomorphic tap wall mimicking the " +
                                "one present in the pub. The website resides on one minimalist page, so users can scroll around the side " +
                                "without navigating elsewhere and can easily engage with the site. Further, to ensure customers get the most up-to-date information, " +
                                "I created an GUI that enables easy access for the owner to switch out which " +
                                "beers they have on tap at any given time", <br/>, <br/>, "If you would like to see the website for yourself, head over to " +
                                "the website ", <a href={"https://mosaictap.com/"} rel={"noreferrer"} target="_blank">here</a>, "."]}
                                screenshots={[
                                    [MosaicLandingPage, "Landing page for Mosaic Tap."],
                                    [MosaicTapWallGIF, "GIF showing interacting with taps on tap wall for Mosaic Tap."],
                                    [MosaicEvents, "Posters displayed for Mosaic Tap."],
                                    [MosaicGallery, "Gallery of images displayed for Mosaic Tap."],
                                    [MosaicVisitUs, "Mosaic Tap map with opening hours."],
                                    [MosaicCraftBeer, "Craft beer trails for Mosaic Tap."],
                                    // [MosaicWallMobile, "Mosaic Tap's, tap wall displayed on mobile.", "3vw"],
                                ]}
                                skills={
                                    <ul>
                                        <li>React</li>
                                        <li>JavaScript</li>
                                        <li>HTML</li>
                                        <li>CSS</li>
                                        <li>Firebase</li>
                                        <li>Web Design</li>
                                    </ul>}
                                background={''}
                                prev={'/projects/dissertation'}
                                next={'/projects/gql-limitations'}
                                setOpen={setOpen}
                                setDialogContent={setDialogContent}
                                setDialogStyle={setDialogStyle}
                                imgSize={'15vw'}
                            />}
                        />
                        <Route exact path="/projects/gql-limitations" element=
                            {<GQLLimitations
                                img={QALogo}
                                title={'Limitations of Graph Query Languages'}
                                intro={["Through the use of query answering, natural language processing and newly " +
                                "proposed generalised semistructured models we will analyse sentences through dependency " +
                                "graphs to compare two or more statements, evaluate their truth, and compare to an " +
                                "external, fact-checked knowledge base for clarification. This page poster explores the " +
                                "initial understanding of the core theory on the limitations of graph query languages " +
                                "and how we can overcome them, linking to the poster presented at the SAgE PGR Conference."]}
                                skills={
                                    <ul>
                                        <li>Graph Query Languages</li>
                                        <li>Neo4j</li>
                                        <li>SPARQL</li>
                                        <li>C++</li>
                                        <li>Graph Grammars</li>
                                    </ul>}
                                background={''}
                                prev={'/projects/mosaic-tap'}
                                next={'/projects/albumdle'}
                                setOpen={setOpen}
                                setDialogContent={setDialogContent}
                                setDialogStyle={setDialogStyle}
                            />}
                        />
                        <Route exact path="/projects/albumdle" element=
                            {<ProjectPage
                                img={AlbumdleLogo}
                                title={'Albumdle'}
                                intro={["A web application that can be played daily, with the goal to try and " +
                                "guess an album cover, as it's revealed one tile at a time."]}
                                desc={["A web application that can be played daily, with the goal to try and " +
                                "guess an album cover, as it's revealed one tile at a time. The player can search " +
                                "through a long list of albums and artists, with up to 16 guesses to get the correct " +
                                "answer.", <br/>, <br/>, "If you would like to play the game for yourself, head over to " +
                                "the website ", <a href={"https://albumdle.com/"} rel={"noreferrer"} target="_blank">here</a>, "."]}
                                screenshots={[
                                    [DemoGif, "Demonstration of Albumdle being played."],
                                    [HowToAlbum, "Screenshot of how to play Albumdle."],
                                    [ShareSS, "Screenshot of the results screen if you complete a round of Albumdle."],
                                    [ResultsSS, "Screenshot showing the total results accumulates over many rounds of Albumdle."]
                                ]}
                                skills={
                                    <ul>
                                        <li>HTML</li>
                                        <li>JavaScript</li>
                                        <li>Firebase</li>
                                        <li>Web Design</li>
                                    </ul>}
                                background={''}
                                prev={'/projects/gql-limitations'}
                                next={'/projects/top-spots'}
                                setOpen={setOpen}
                                setDialogContent={setDialogContent}
                                setDialogStyle={setDialogStyle}
                            />}
                        />
                        <Route exact path="/projects/top-spots" element=
                            {<ProjectPage
                                img={TopSpotsLogo}
                                title={'Top Spots'}
                                intro={['An application I created in lockdown for people to ' +
                                'discover and add new ', <i>spots</i>, ' near them.']}
                                desc={['Like many others no wonder, lockdown was an incredibly challenging time for most, ' +
                                'being stuck indoors, repeating the same set of tasks day after day. I wanted to occupy ' +
                                'myself with something to make the most of this new found time, this is where ',<i>Top Spots</i>,
                                ' was born.', <br/>, <br/>, "If you would like to check it out for yourself, head over to " +
                                    "the website ", <a href={"https://topspots.co.uk/"} rel={"noreferrer"} target="_blank">here</a>, ", where you'll " +
                                    "find a link to the Play Store if you'd like to download."]}
                                screenshots={[
                                    [TS1, ""],
                                    [TS2, ""],
                                    [TS3, ""],
                                    [TS4, ""]
                                ]}
                                skills={
                                    <ul>
                                        <li>Android</li>
                                        <li>Java</li>
                                        <li>Firebase</li>
                                        <li>Google Maps / Directions API</li>
                                    </ul>}
                                background={''}
                                prev={'/projects/albumdle'}
                                next={'/projects/dissertation'}
                                setOpen={setOpen}
                                setDialogContent={setDialogContent}
                                setDialogStyle={setDialogStyle}
                            />}
                        />
                        <Route exact path="/projects/dissertation" element=
                            {<ProjectPage
                                img={DissLogo}
                                title={'Third Year Dissertation'}
                                intro={['Learning Navigation on Procedurally Generated Cities: ' +
                                'A Reinforcement Learning Perspective']}
                                desc={["My third year dissertation project on reinforcement learning. This was an incredibly " +
                                "fun investigation for my third year project, building a procedurally generated city in Unity, " +
                                "then training a neural network on a 'car' to navigate it. The car would be faced with various " +
                                "'roadblocks' which it would need to avoid in order to reach the given destination.",<br/>, <br/>,
                                "The results of these tests were then compared to a more traditional, unsupervised learning method " +
                                "to see which performed best.", <br/>, <br/>, "If you would like to try the program for yourself, " +
                                "click ", <a href={'/projects/dissertation/demo'}>here</a>, "."]}
                                screenshots={[
                                    [LargeCityCloseUp, ""],
                                    [LargeCityTraining, ""],
                                    [AppliedNN, ""]
                                ]}
                                skills={
                                    <ul>
                                        <li>Unity</li>
                                        <li>C#</li>
                                        <li>Machine (Reinforcement) Learning</li>
                                        <li>Supervised Learning (A* with Replanning)</li>
                                        <li>Data Analysis</li>
                                    </ul>}
                                background={''}
                                prev={'/projects/top-spots'}
                                next={'/projects/mosaic-tap'}
                                setOpen={setOpen}
                                setDialogContent={setDialogContent}
                                setDialogStyle={setDialogStyle}
                            />}
                        />
                        <Route exact path="/projects/dissertation/demo" element={<RLDemo />} />
                        <Route exact path="/demo" element={<RLDemo />} />
                        <Route exact path="/work" element={<Work />} />
                        <Route exact path="/work/development" element={<Development />} />
                        <Route exact path="/work/media" element={<Media
                            setOpen={setOpen}
                            setDialogContent={setDialogContent}
                            setDialogStyle={setDialogStyle}/>} />
                        <Route exact path="/work/design" element={<Media
                            setOpen={setOpen}
                            setDialogContent={setDialogContent}
                            setDialogStyle={setDialogStyle}/>} />
                        <Route exact path="/about" element={<About
                            setOpen={setOpen}
                            setDialogContent={setDialogContent}
                            setDialogStyle={setDialogStyle}/>}  />
                    </Routes>
                </div>
            </div>
        </Router>
      </div>
    );
}

export default App;
