import React, {useEffect, useState} from 'react';
import './contactDialog.css';
import './contactDialogMobile.css';
import anime from "animejs";



const ContactDialog = (props) => {
    let open = props.open;
    let setOpen = props.setOpen;
    let content = props.content;
    let style = props.style;
    let setStyle = props.setStyle;

    const [displayStyle, setDisplayStyle] = useState("none");

    useEffect(() => {
        if (content != null) {
            if (content.length > 0 && content[0].type !== "img" && content[0].props.children[0].includes("I am available for commission")) {
                setStyle({backgroundColor: '#262626'})
            }
        }

        if (open) {
            setDisplayStyle("flex");
            anime({
                targets: '.contactContainer',
                opacity: ['0%', '100%'],
                easing: 'easeInOutQuad',
                duration: 200
            });
        } else {
            anime({
                targets: '.contactContainer',
                opacity: ['100%', '0%'],
                easing: 'easeInOutQuad',
                duration: 200,
                complete: function(anim) {
                    if (anim.progress === 100) {
                        setDisplayStyle( "none");
                        setStyle({});
                    }
                }
            });
        }
    }, [open])

    return (
        <div onClick={() => {
            setOpen(false)
        }} style={{display: displayStyle}} className='contactContainer noSelect'>
            <div style={style} onClick={(e) => {e.stopPropagation();}} className='contactDialogBoxContainer'>
                {content}
            </div>
        </div>
    )
}

export default ContactDialog;