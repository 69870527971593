import React, { useEffect } from 'react';
import './circle.css';
import './circleMobile.css';
import $ from 'jquery';

import anime from "animejs";

const Circle = (props) => {
    let title = props.title;
    let company = props.company;
    let desc = props.desc;
    let side = props.side;
    let start = props.start;
    let end = props.end;
    let last = props.last;

    function detectMob() {
        return window.innerWidth <= 640;
    }

    useEffect(() => {
        $('.circle').each(function() {
            $(this).css('background-color', "#" + (Math.random() * 16777215 | 0).toString(16))
        })
    }, [])

    return (
        <div className={`${side}CircleContainer`}>
            <div className={`circle noSelect`} onMouseEnter={() => {
                if (last) {
                    anime({
                        targets: `.skillsTitle`,
                        marginTop: [50, detectMob() ? 125 : 75],
                        easing: 'spring',
                        duration: 100
                    });
                }

                anime({
                    targets: `.${company.replaceAll(" ", "")}CircleText`,
                    marginTop: [0, detectMob() ? -100 : -150],
                    easing: 'spring',
                    duration: 100
                });
                anime({
                    targets: `.${company.replaceAll(" ", "")}`,
                    display: 'block',
                    // marginTop: [-110, 10],
                    opacity: ['0%', '100%'],
                    easing: 'spring',
                    duration: 100
                });
            }} onMouseLeave={() => {
                if (last) {
                    anime({
                        targets: `.skillsTitle`,
                        marginTop: [detectMob() ? 125 : 75, 50],
                        easing: 'spring',
                        duration: 100
                    });
                }

                anime({
                    targets: `.${company.replaceAll(" ", "")}CircleText`,
                    marginTop: [detectMob() ? -100 : -150, 0],
                    easing: 'spring',
                    duration: 100
                });
                anime({
                    targets: `.${company.replaceAll(" ", "")}`,
                    opacity: ['100%', '0%'],
                    easing: 'spring',
                    duration: 100
                });
            }}>

            </div>
            <div className={`${side}CircleText ${company.replaceAll(" ", "")}CircleText`}>
                <div className='circleTitle'>{title}</div>
                <div className='circleCompany'><i>{company}</i></div>
                <div className={`circleDesc ${company.replaceAll(" ", "")}`}>
                    {desc}
                    <div className='timeRange'>
                        <i>{start} - {end}</i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Circle