import React, { useEffect } from 'react';
import './media.css';
import './mediaMobile.css';
import anime from "animejs";

import {
    ShesAWomanArt,
    EMAlbumArt,
    SpeedLimitArt,
    WabiSabiArt,
    FirstContactArt,
    TINOYSArt,
    UAMFleetPoster,
    UAMAldershotPoster,
    UAMLoungeBarPoster,
    EMLoungeBarPoster,
    EMGoodIntentPoster,
    EMPipelinePoster,
    EMHeartbreakersPoster,
    TegaluCatLogo,
    NoKiteLogo,
    KCLCSLogo,
    UpHereImg,
    UpHereImgMobile,
    TunkuskaLogo,
    BTHeartbreakersPoster,
    BTPipelinePoster,
    BloatwareLogo,
    LongWayHome,
    UAMFollyPoster,
    TheFirewall,
    DownDown,
    BTPipelinePosterThumb,
    UAMFollyPosterThumb,
    BTHeartbreakersPosterThumb,
    UAMFleetPosterThumb,
    UAMAldershotPosterThumb,
    UAMLoungeBarPosterThumb,
    EMLoungeBarPosterThumb,
    EMPipelinePosterThumb,
    EMHeartbreakersPosterThumb,
    EMGoodIntentPosterThumb,
    TheFirewallThumb,
    ShesAWomanArtThumb,
    WabiSabiArtThumb,
    TINOYSArtThumb,
    DownDownThumb,
    LongWayHomeThumb,
    EMAlbumArtThumb,
    SpeedLimitArtThumb,
    FirstContactArtThumb,
    TegaluCatLogoThumb,
    BloatwareLogoThumb,
    ChangeCommandThumb,
    ChangeCommand,
    NoKiteFollyPosterThumb,
    NoKiteFollyPoster,
    JJHLogo,
    LimestoneCube,
    LimestoneCubeThumb,
    AntichristThumb,
    Antichrist,
    BTLogo,
    TrojanHorse,
    TrojanHorseThumb,
    MyopiaThumb,
    Myopia,
    ByTheWay, ByTheWayThumb
} from "../../../img/work/media";
import {Arrow, AlbumdleLogo, TopSpotsLogo, Logo, Parchment} from "../../../img";

import {ImageWithLocation, resetLogo} from "../../../components";
import {useLocation} from "react-router-dom";

const Media = (props) => {
    let setOpen = props.setOpen;
    let setDialogContent = props.setDialogContent;
    let setStyle = props.setDialogStyle;

    const location = useLocation();

    let images = [
        [ShesAWomanArt, 'https://open.spotify.com/track/5Xe7lSFL3rryeFyw8aC0Sn?si=e3f2f31600d64322', "She's A Woman album artwork"],
        [EMAlbumArt, '', ''],
        [SpeedLimitArt, '', ''],
        [WabiSabiArt, '', ''],
        [FirstContactArt, '', ''],
        [TINOYSArt, '', ''],
        [UAMFleetPoster, '', ''],
        [UAMAldershotPoster, '', ''],
        [UAMLoungeBarPoster, '', ''],
        [EMLoungeBarPoster, '', ''],
        [EMGoodIntentPoster, '', ''],
        [EMPipelinePoster, '', ''],
        [EMHeartbreakersPoster, '', ''],
        [TegaluCatLogo, '', ''],
        [NoKiteLogo, '', ''],
        [KCLCSLogo, '', ''],
        [TunkuskaLogo, '', ''],
        [BTHeartbreakersPoster, '', ''],
        [BTPipelinePoster, '', '']
    ];

    shuffle(images)
    const mappedImages = images.map((item, index) =>
        <div className='gridItem albumItem'>
            <img
                onClick={() => {
                    window.open(item[1])
                }}
                className='mediaImg albumImg'
                src={item[0]}
                alt={item[2]}/>
        </div>)

    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex > 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    function openImage(src, alt, link, type) {
        setOpen(true)
        setDialogContent([
            <img
                className={'dialogImg'}
                src={src}
                alt={alt}
                onClick={() => {
                    if (link !== '') {
                        window.open(link)
                    }
                }}
            />])
        if (detectMob()) {
            setStyle({width: "85%", padding: "0"});
        } else {
            if (type === 'poster' || type === 'album') {
                setStyle({height: "calc(100vh - 300px)", width: "auto", padding: "0", backgroundColor: 'rgba(0,0,0,0)'});
            } else if (type === 'medium') {
                setStyle({height: "calc(100vh - 650px)", width: "auto", padding: "0", backgroundColor: 'rgba(0,0,0,0)'});
            } else if (type === 'long') {
                setStyle({height: "calc(100vh - 1000px)", width: "auto", padding: "0", backgroundColor: 'rgba(0,0,0,0)'});
            } else {
                setStyle({width: "auto", padding: "0", backgroundColor: 'rgba(0,0,0,0)'});
            }
            // setStyle({height: "calc(100vh - 300px)", width: "auto", padding: "0", backgroundColor: 'rgba(0,0,0,0)'});
            // setStyle({height: "calc(100vh - 150px)", width: "auto"});
        }
    }

    function detectMob() {
        return window.innerWidth <= 640;
    }

    useEffect(() => {
        document.title = `Oliver Fox - Media`;
        resetLogo();

        let basicTimeline = anime.timeline({
            easing: 'easeInOutQuad'
        });

        basicTimeline
            .add({
                targets: '.mediaContainer',
                translateY: [-10, 0],
                opacity: ['0%', '100%'],
                duration: 600,
            })
    }, [location])

    return (
        <div className='mediaContainer'>
            <ImageWithLocation img={detectMob() ? UpHereImgMobile : UpHereImg} location={'Abbey Road Studios'}
                               alt={"Stood in Studio 3 at Abbey Road Studios"}
                               width={detectMob() ? '100%' : 'max(45vw, 500px)'}
                               direction={'column-reverse'} marginTopBefore={0} marginTopAfter={0}
                               marginBottomBefore={-75} marginBottomAfter={20}/>
            {/*<h2 className='mediaAlbumTitle'>Album Art</h2>*/}
            <div className='mediaDesc mediaAlbumDesc'>
                Throughout the years, I've had the privilege to create album and single artwork for some of the best
                upcoming bands in my local area, including: <a href={'https://www.instagram.com/big.trousers/'}>Big
                Trousers</a>, <a
                    href={"https://open.spotify.com/artist/4W9f5emYaGQnxlcZ6SOhQk?si=qNtLrWXsSne4q2HUCUBUlg"}>Unlucky @
                    Midnight</a>, <a
                href={"https://open.spotify.com/artist/4lNieU3aPlri0kbWmd5UoC?si=tjykQozGTyaNUVoqOJYPyA"}>Electric
                Milk</a> and <a href={"https://open.spotify.com/artist/4VsWKLVdPMA7QC6wNqlqc7?si=5837fd9dc3a64920"}>William
                Reigate</a>.
                <br/><br/>
                On top of the album artwork, promotional material is essential in a band's social media outreach, and have
                created posters for many of the aforementioned bands.
                <br/><br/>
                Furthering my graphic design capabilities, I've carried out work for people involving logo design.
                Including: <a href={'https://www.instagram.com/tegalucat//'}>Tegalü Cat</a>, <a
                href={'https://www.instagram.com/nokitegram/'}>No Kite</a>,
                the <a href={'https://www.instagram.com/kclcybersociety/'}>King's College London Cyber Security
                Society</a>, <a href={'https://www.instagram.com/tunguskaband/'}>Tungüska</a>, as well as logos for my
                own projects.
            </div>
            <div className='mediaCarouselGrid mediaAlbumGrid'>
                {/*{mappedImages}*/}
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(ByTheWay,
                                'Myopia single artwork',
                                '',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={ByTheWayThumb}
                        alt={'By The Way single artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(Myopia,
                                'Myopia single artwork',
                                '',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={MyopiaThumb}
                        alt={'Myopia single artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(TrojanHorse,
                                'Trojan Horse single artwork',
                                '',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={TrojanHorseThumb}
                        alt={'Trojan Horse single artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(Antichrist,
                                'Antichrist single artwork',
                                'https://open.spotify.com/album/3UoYScO2yRxbHGAFhlIxVE?si=OlNfFdNWQeGiS6EheRjBMw',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={AntichristThumb}
                        alt={'Antichrist single artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(LimestoneCube,
                                'ME AND MY MASSIVE LIMESTONE CUBE single artwork',
                                'https://open.spotify.com/album/7zif8IjtfW6im7lrBWrTZr?si=gkRfIVYpT1yZPfK7CEUNLw',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={LimestoneCubeThumb}
                        alt={'ME AND MY MASSIVE LIMESTONE CUBE single artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(ChangeCommand,
                                'Change Command single artwork',
                                '',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={ChangeCommandThumb}
                        alt={'Change Command single artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(DownDown,
                                'Down Down single artwork',
                                'https://www.instagram.com/p/C3upRiltudO/?img_index=1',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={DownDownThumb}
                        alt={'Down Down single artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(TheFirewall,
                                'The Firewall single artwork',
                                'https://www.instagram.com/bloatwaresounds',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={TheFirewallThumb}
                        alt={'The Firewall single artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(LongWayHome,
                                'Long Way Home album artwork',
                                '',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={LongWayHomeThumb}
                        alt={'Long Way Home album artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(
                                ShesAWomanArt,
                                "She's a Woman single artwork",
                                'https://open.spotify.com/track/5Xe7lSFL3rryeFyw8aC0Sn?si=e3f2f31600d64322',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={ShesAWomanArtThumb}
                        alt={"She's a Woman single artwork"}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(
                                EMAlbumArt,
                                "Electric Milk album artwork",
                                'https://open.spotify.com/album/1vuoC71yP6ma9JVmTx1uPx?si=e259c5ab74a24f7d',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={EMAlbumArtThumb}
                        alt={'Electric Milk album artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(
                                SpeedLimitArt,
                                "Speed Limit single artwork",
                                'https://open.spotify.com/album/3v0cqiDoLfDVCKB5sXHP1s?si=KjidQXnTSXyiILCDK7HpYg',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={SpeedLimitArtThumb}
                        alt={'Speed Limit single artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(
                                WabiSabiArt,
                                "Wabi Sabi single artwork",
                                'https://open.spotify.com/album/3JlsGn3OHruuitxzUFBJIm?si=9Iqt0HiVTeGEoWdaXfsIYA',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={WabiSabiArtThumb}
                        alt={'Wabi Sabi single artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(
                                FirstContactArt,
                                "First Contact single artwork",
                                'https://open.spotify.com/album/0RG1H7mkrI4TkIQMGcqjgN?si=XXHQ4867Rle16fTo3gKT6g',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={FirstContactArtThumb}
                        alt={'First Contact single artwork'}/>
                </div>
                <div className='gridItem albumItem'>
                    <img
                        onClick={() => {
                            openImage(
                                TINOYSArt,
                                "Time Is Never On Your Side (unreleased) single artwork",
                                'https://open.spotify.com/track/6RmRCFNCgoO7sQZdYGjMFW?si=40f729512c6c4352',
                                'album')
                        }}
                        className='mediaImg albumImg'
                        src={TINOYSArtThumb}
                        alt={'Time Is Never On Your Side (unreleased) single artwork'}/>
                </div>
            </div>

            {/*<h2 className='mediaPosterTitle mediaTitle'>Posters</h2>*/}
            {/*<div className='mediaDesc mediaPosterDesc'>*/}

            {/*</div>*/}
            <br/>
            <div className='mediaCarouselGrid mediaPosterGrid'>
                <div className='gridItem posterItem'>
                    <img
                        onClick={() => {
                            openImage(
                                NoKiteFollyPoster,
                                "No Kite Folly poster",
                                '',
                                'poster')
                        }}
                        className='mediaImg posterImg'
                        src={NoKiteFollyPosterThumb}
                        alt={"No Kite Folly poster"}/>
                </div>
                <div className='gridItem posterItem'>
                    <img
                        onClick={() => {
                            openImage(
                                BTPipelinePoster,
                                "Big Trousers Pipeline poster",
                                '',
                                'poster')
                        }}
                        className='mediaImg posterImg'
                        src={BTPipelinePosterThumb}
                        alt={"Big Trousers Pipeline poster"}/>
                </div>
                <div className='gridItem posterItem'>
                    <img
                        onClick={() => {
                            openImage(
                                UAMFollyPoster,
                                "Unlucky @ Midnight Folly poster",
                                'https://www.instagram.com/p/C2t4if7rSDV/?img_index=1',
                                'poster')
                        }}
                        className='mediaImg posterImg'
                        src={UAMFollyPosterThumb}
                        alt={"Unlucky @ Midnight Folly poster"}/>
                </div>
                <div className='gridItem posterItem'>
                    <img
                        onClick={() => {
                            openImage(
                                BTHeartbreakersPoster,
                                "Big Trousers Heartbreakers' poster",
                                'https://www.instagram.com/p/C1Cs5BmrwcP/?img_index=2',
                                'poster')
                        }}
                        className='mediaImg posterImg'
                        src={BTHeartbreakersPosterThumb}
                        alt={"Big Trousers Heartbreakers' poster"}/>
                </div>
                <div className='gridItem posterItem'>
                    <img
                        onClick={() => {
                            openImage(
                                UAMFleetPoster,
                                "Unlucky @ Midnight Falkner's Arms poster",
                                'https://www.instagram.com/p/CwNHMtltx5r/',
                                'poster')
                        }}
                        className='mediaImg posterImg'
                        src={UAMFleetPosterThumb}
                        alt={"Unlucky @ Midnight Falkner's Arms poster"}/>
                </div>
                <div className='gridItem posterItem'>
                    <img
                        onClick={() => {
                            openImage(
                                UAMAldershotPoster,
                                "Unlucky @ Midnight Funky End poster",
                                'https://www.instagram.com/p/CrLh7kJN7ia/',
                                'poster')
                        }}
                        className='mediaImg posterImg'
                        src={UAMAldershotPosterThumb}
                        alt={'Unlucky @ Midnight Funky End poster'}/>
                </div>
                <div className='gridItem posterItem'>
                    <img
                        onClick={() => {
                            openImage(
                                UAMLoungeBarPoster,
                                "Unlucky @ Midnight Lounge Bar poster",
                                'https://www.instagram.com/p/CiaxHEytG3M/',
                                'poster')
                        }}
                        className='mediaImg posterImg'
                        src={UAMLoungeBarPosterThumb}
                        alt={'Unlucky @ Midnight Lounge Bar poster'}/>
                </div>
                <div className='gridItem posterItem'>
                    <img
                        onClick={() => {
                            openImage(
                                EMLoungeBarPoster,
                                "Electric Milk Lounge Bar poster",
                                'https://www.instagram.com/p/CWqwAPKL1v6/',
                                'poster')
                        }}
                        className='mediaImg posterImg'
                        src={EMLoungeBarPosterThumb}
                        alt={'Electric Milk Lounge Bar poster'}/>
                </div>
                <div className='gridItem posterItem'>
                    <img
                        onClick={() => {
                            openImage(
                                EMPipelinePoster,
                                "Electric Milk The Pipeline poster",
                                'https://www.instagram.com/p/CR_0DzNIguN/',
                                'poster')
                        }}
                        className='mediaImg posterImg'
                        src={EMPipelinePosterThumb}
                        alt={'Electric Milk The Pipeline poster'}/>
                </div>
                <div className='gridItem posterItem'>
                    <img
                        onClick={() => {
                            openImage(
                                EMHeartbreakersPoster,
                                "Electric Milk Heartbreakers poster",
                                'https://www.instagram.com/p/CRg92xwrYdN/',
                                'poster')
                        }}
                        className='mediaImg posterImg'
                        src={EMHeartbreakersPosterThumb}
                        alt={'Electric Milk Heartbreakers poster'}/>
                </div>
                <div className='gridItem posterItem'>
                    <img
                        onClick={() => {
                            openImage(
                                EMGoodIntentPoster,
                                "Electric Milk The Good Intent poster",
                                'https://www.instagram.com/p/BwhmAA9HRyF/',
                                'poster')
                        }}
                        className='mediaImg posterImg'
                        src={EMGoodIntentPosterThumb}
                        alt={'Electric Milk The Good Intent poster'}/>
                </div>
            </div>
            <br/>
            <div className='mediaCarouselGrid mediaLogoGrid'>
                <div className='gridItem logoItem'>
                    <img
                        onClick={() => {
                            openImage(
                                BTLogo,
                                "Big Trousers logo",
                                'https://www.instagram.com/big.trousers',
                                'medium')
                        }}
                        className='mediaImg logoImg'
                        src={BTLogo}
                        alt={'Big Trousers logo'}/>
                </div>
                <div className='gridItem logoItem'>
                    <img
                        onClick={() => {
                            openImage(
                                JJHLogo,
                                "JJH Music Tuition logo",
                                '',
                                'medium')
                        }}
                        className='mediaImg logoImg'
                        src={JJHLogo}
                        alt={'JJH Music Tuition logo'}/>
                </div>
                <div className='gridItem logoItem'>
                    <img
                        onClick={() => {
                            openImage(
                                BloatwareLogo,
                                "Bloatware logo",
                                'https://www.instagram.com/bloatwaresounds',
                                'medium')
                        }}
                        className='mediaImg logoImg'
                        src={BloatwareLogoThumb}
                        alt={'Bloatware logo'}/>
                </div>
                <div className='gridItem logoItem'>
                    <img
                        onClick={() => {
                            openImage(
                                TunkuskaLogo,
                                "Tungüska logo",
                                'https://www.instagram.com/p/Czy1KD7CxOE/?img_index=1',
                                'long')
                        }}
                        className='mediaImg logoImg'
                        src={TunkuskaLogo}
                        alt={'Tungüska logo'}/>
                </div>
                <div className='gridItem logoItem'>
                    <img
                        onClick={() => {
                            openImage(
                                TegaluCatLogo,
                                "Tegalü Cat logo",
                                'https://www.instagram.com/tegalucat/',
                                'medium')
                        }}
                        className='mediaImg logoImg'
                        src={TegaluCatLogoThumb}
                        alt={'Tegalü Cat logo'}/>
                </div>
                <div className='gridItem logoItem'>
                    <img
                        onClick={() => {
                            openImage(
                                NoKiteLogo,
                                "No Kite logo",
                                'https://www.instagram.com/nokitegram/',
                                'long')
                        }}
                        className='mediaImg logoImg'
                        src={NoKiteLogo}
                        alt={'No Kite logo'}/>
                </div>
                <div className='gridItem logoItem'>
                    <img
                        onClick={() => {
                            openImage(
                                KCLCSLogo,
                                "King's College London Cyber Security Society logo",
                                'https://www.instagram.com/kclcybersociety/',
                                'medium')
                        }}
                        className='mediaImg logoImg'
                        src={KCLCSLogo}
                        alt={"King's College London Cyber Security Society logo"}/>
                </div>
                <div className='gridItem logoItem'>
                    <img
                        onClick={() => {
                            openImage(
                                Logo,
                                "Oliver Fox portfolio logo",
                                'https://ofox.co.uk/work/media')
                        }}
                        className='mediaImg logoImg'
                        src={Logo}
                        alt={"Oliver Fox portfolio logo"}/>
                </div>
                <div className='gridItem logoItem'>
                    <img
                        onClick={() => {
                            openImage(
                                AlbumdleLogo,
                                "Albumdle logo",
                                'https://albumdle.com/',
                                'medium')
                        }}
                        className='mediaImg logoImg'
                        src={AlbumdleLogo}
                        alt={"Albumdle logo"}/>

                </div>
                <div className='gridItem logoItem'>
                    <img
                        onClick={() => {
                            openImage(
                                TopSpotsLogo,
                                "Top Spots logo",
                                'https://topspots.co.uk/')
                        }}
                        className='mediaImg logoImg'
                        src={TopSpotsLogo}
                        alt={"Top Spots logo"}/>
                </div>
            </div>
        </div>
    )
}

export default Media